import styled from "styled-components";
import { theme } from "../../theme";
type Width = {
  customWidth?: string,
  customHeight?: string,
  customBg?: string,
  customBorder?: string,
};

export const Button = styled.button<Width>`
  cursor: pointer;
  background: ${({ theme: { colors },  customBg})=> customBg === 'aqua' ? colors.darkAquaColor : customBg === 'white' ? colors.simpleWhite : colors.mainBlack};
  color: ${({ theme: { colors },  customBg})=> customBg === 'white' ? colors.darkAquaColor : colors.simpleWhite};
  width: ${({customWidth}) => customWidth? customWidth : '100%'};
  height: ${({customHeight}) => customHeight? customHeight : '36px'};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: ${({customBg})=> customBg? '600' : '500'};
  font-size: 14px;
  line-height: 20px;
  border: ${({ theme: { colors },  customBg})=> customBg === 'white' ? `solid 1px ${colors.mainGray}` : 'none'};

  &:disabled {
    background: gray;
  }
`;

export const ButtonOutline = styled(Button)`
  background: ${({theme}) => theme.colors.simpleWhite};
  color: ${({theme}) => theme.colors.mainBlack};
  border: 1px solid ${({theme}) => theme.colors.mainBlack};
`;