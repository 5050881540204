import { HeadFC, navigate } from 'gatsby';
import * as React from 'react';

import ios from '../assets/app-store-btn.svg';
import google from '../assets/google-play-btn.svg';
import step_1 from '../assets/step1.svg';
import { Button } from '../components/Button';
import Footer from '../components/Footer';
import NavBar from '../components/Nav';
import { Seo } from '../components/Seo';
import { Spacer } from '../components/Spacer';
import { environments } from '../config';
import { fontWeightType } from '../constants/enums';
import Base from '../layout/Base';
import {
  AppsContainerImg,
  HomeHeader,
  HomeHeaderAppsContainer,
  HomeHeaderImage,
  HomeHeaderText,
  HomeHeaderTitle,
} from '../styled-components/pages/home.style';

const IndexPage = () => {
  return (
    <Base>
      <NavBar spacerSize="0" logoCenter />
      <HomeHeader>
        <HomeHeaderImage src={step_1} alt="step-1" />
        <HomeHeaderTitle color="grayBlack" fontWeight={fontWeightType.SemiBold} className="title">
          Estudia conceptos médicos
        </HomeHeaderTitle>
        <HomeHeaderText color="mainGray" fontWeight={fontWeightType.Medium} className="text">
          Practica de forma remota para tu examen de residencia con nuestro banco de +3500 preguntas
          que abarcan varias especialidades de la Medicina.
        </HomeHeaderText>
        {environments.HIDE_VIEWS !== 'true' && (
          <Button customWidth="300px" customHeight="44px" onClick={() => navigate('/login')}>
            Inicia sesión
          </Button>
        )}
        <Spacer size="30" />
        <HomeHeaderAppsContainer>
          <a href={environments.PLAYSTORE_URL} target="_blank">
            <AppsContainerImg src={google} alt="google" className="apps" />
          </a>
          <a href={environments.APPSTORE_URL} target="_blank">
            <AppsContainerImg src={ios} alt="ios" className="apps" />
          </a>
        </HomeHeaderAppsContainer>
        <Spacer size="81" />
      </HomeHeader>
      <Footer />
    </Base>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <Seo />;
